interface WidgetData {
  id: string;
  created_on: string;
  phone: string;
  bg_color: string;
  icon_color: string;
  default_text: string;
  button_1_text: string;
  button_2_text: string;
  button_3_text: string;
  position: 'BOTTOM_RIGHT' | 'BOTTOM_LEFT';
  padding: number;
  version: string;
  account: number;
  channel: number;
}

const BASE_URL = 'https://api.hilos.io';

document.addEventListener('DOMContentLoaded', async function() {
  const scriptTag = document.querySelector(
    'script[data-widget-id]'
  ) as HTMLScriptElement | null;
  if (!scriptTag) {
    throw new Error('Error initializing widget: Script tag not found');
  }

  const widgetId = scriptTag.getAttribute('data-widget-id');
  if (!widgetId) {
    throw new Error('Error initializing widget: Widget ID not found');
  }

  const fontLink = document.createElement('link');
  fontLink.href = 'https://use.typekit.net/zhv2tlh.css';
  fontLink.rel = 'stylesheet';
  document.head.appendChild(fontLink);

  try {
    const widgetSettings = await fetch(
      `${BASE_URL}/public/button/${widgetId}/`
    ).then(response => response.json());

    const whatsappIcon = await fetch(
      'https://api.iconify.design/ic/baseline-whatsapp.svg'
    )
      .then(response => response.text())
      .then(svg => svg.replace('currentColor', widgetSettings.icon_color));

    const airplaneIcon = await fetch(
      'https://api.iconify.design/fe/paper-plane.svg'
    )
      .then(response => response.text())
      .then(svg => svg.replace('currentColor', widgetSettings.icon_color));

    const style = document.createElement('style');
    style.textContent = `
      #hilos-widget-wrapper {
        all: unset;
        position: fixed;
        letter-spacing: 0;
        box-sizing: border-box;
        bottom: 0;
        padding: ${widgetSettings.padding}px;
        width: clamp(200px, calc(100% - ${widgetSettings.padding *
          2}px), 400px);
        color: rgba(51, 65, 85, 1);
        z-index: 1000;
        display: flex;
        flex-direction: column;
        ${widgetSettings.position === 'BOTTOM_LEFT' ? 'left: 0;' : 'right: 0;'}
        align-items: ${
          widgetSettings.position === 'BOTTOM_LEFT' ? 'flex-start' : 'flex-end'
        };
        font-family: "inter-variable", sans-serif;
      }

      #hilos-widget-window {
        border-top: 10px solid ${widgetSettings.bg_color};
        display: none;
        width: 100%;
        flex-direction: column;
        gap: 0.5rem;
        align-items: left;
        background-color: white;
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
        border-radius: 0.75rem;
        padding: 20px 16px 12px 16px;
        position: relative;
        bottom: 15px;
        justify-content: center;
      }

      #hilos-widget-button {
        position: relative;
        bottom: 0;
        background-color: ${widgetSettings.bg_color};
        width: 54px;
        height: 54px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      }

      #hilos-widget-button svg {
        width: 24px;
        height: 24px;
      }

      #hilos-quick-replies-row {
        display: flex;
        flex-wrap: wrap;
        gap: 0.75rem;
        margin-bottom: 0.5rem;
      }

      #hilos-main-row {
        display: flex;
        gap: 0.75rem;
      }

      #hilos-attribution-row {
        margin: 3px auto 0;
      }

      .hilos-reply-button {
        cursor: pointer;
        padding: 8px 16px;
        border: none;
        border-radius: 3rem;
        font-size: 0.85rem;
        background-color: #F2F2F2;
        color: rgb(51 65 85);
      }

      .hilos-widget-icon {
        width: 21px;
        height: 21px;
      }

      #hilos-default-text {
        display: flex;
        font-size: 0.85rem;
        color: #737373;
        border: 1px solid rgba(209, 213, 219, 1);
        align-items: center;
        justify-content: flex-start;
        border-radius: 6rem;
        width: 80%;
        height: auto;
        padding: 0.75rem 1rem;
      }

      #hilos-send-button {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: none;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: ${widgetSettings.bg_color};
      }

      #hilos-send-button svg {
        width: 20px;
        height: 20px;
      }

      #hilos-widget-attribution {
        all: unset;
        font-size: 0.7rem;
        text-decoration: none;
        color: ${widgetSettings.bg_color};
        border-radius: 0.25rem;
        padding: 6px 8px;
        cursor: pointer;
      }

      #hilos-widget-attribution strong{
        font-variation-settings: 'wght' 600;
      }
    `;

    document.head.appendChild(style);

    const createWidget = (data: WidgetData) => {
      const widgetContainer = document.createElement('div');
      widgetContainer.id = 'hilos-widget-wrapper';

      const button = document.createElement('div');
      button.id = 'hilos-widget-button';
      button.innerHTML = whatsappIcon;
      button.onclick = () => toggleWidgetContent();

      const content = document.createElement('div');
      content.id = 'hilos-widget-window';

      // Quick replies row
      const quickRepliesRow = document.createElement('div');
      quickRepliesRow.id = 'hilos-quick-replies-row';
      content.appendChild(quickRepliesRow);

      const quickReplies = [
        data.button_1_text,
        data.button_2_text,
        data.button_3_text,
      ].filter(Boolean);
      quickReplies.forEach((text: string, index: number) => {
        const replyButton = document.createElement('button');
        replyButton.classList.add('hilos-reply-button');
        replyButton.textContent = text;
        replyButton.onclick = () => handleButtonClick(data.id, index + 1);
        quickRepliesRow.appendChild(replyButton);
      });

      // Main row
      const mainRow = document.createElement('div');
      mainRow.id = 'hilos-main-row';
      content.appendChild(mainRow);

      const defaultText = document.createElement('div');
      defaultText.textContent = data.default_text;
      defaultText.id = 'hilos-default-text';
      mainRow.appendChild(defaultText);

      const sendButton = document.createElement('button');
      sendButton.innerHTML = airplaneIcon;
      sendButton.id = 'hilos-send-button';
      sendButton.onclick = () => handleButtonClick(data.id, 0);
      mainRow.appendChild(sendButton);

      // Attribution
      const attributionRow = document.createElement('div');
      attributionRow.id = 'hilos-attribution-row';
      content.appendChild(attributionRow);

      const attributionText = document.createElement('div');
      attributionText.innerHTML = `<a href="https://hilos.io" id="hilos-widget-attribution">Powered by <strong>Hilos.io</strong></a>`;
      attributionRow.appendChild(attributionText);

      widgetContainer.appendChild(content);
      widgetContainer.appendChild(button);
      document.body.appendChild(widgetContainer);
    };

    const toggleWidgetContent = () => {
      const content = document.getElementById('hilos-widget-window');
      if (content) {
        content.style.display =
          content.style.display === 'flex' ? 'none' : 'flex';
      }
    };

    const handleButtonClick = (buttonId: string, index: number) => {
      const urlParams = new URLSearchParams(window.location.search);
      const url = new URL(`${BASE_URL}/b/${buttonId}/${index}`);
      urlParams.forEach((value, key) => url.searchParams.append(key, value));
      window.open(url.toString(), '_blank');
    };

    createWidget(widgetSettings);
  } catch (error) {
    console.error('Error initializing widget:', error);
  }
});
